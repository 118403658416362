import React from "react";
import { Link, useNavigate } from "react-router-dom";

const ShippingAndDelivery = () => {
  const navigate = useNavigate()
  return (
    <>
     <div
      style={{
        backgroundColor: "rgb(250, 102, 0)",
        height: "60px",
        width: "100%", 
        position: "fixed",
        top: "0",
        left: "0",
        display: "flex", 
        alignItems: "center", 
        justifyContent: "space-between", 
        padding: "0 20px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", 
        zIndex: 1000,
      }}
    >
     
      <Link to="/" style={{ textDecoration: "none" }}>
        <h2 style={{ color: "#fff", margin: 0 }}>AstroCall</h2>
      </Link>
    
     
      <nav>
        <Link to="/" style={{ color: "#fff", margin: "0 10px", textDecoration: "none" }}>
          Home
        </Link>
      </nav>
    </div>

      <div style={{ paddingLeft: "150px", paddingTop: "100px", paddingRight: "150px", paddingBottom: "40px" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold", textDecoration: "underline" }}>SHIPPING AND DELIVERY POLICY</h3>
        <p>This Shipping and Delivery policy is an integral component of, and should be read in conjunction with, the Terms and Conditions of the website and Mobile Application. We retain the right to modify this Shipping and Delivery at any given time.</p>
        <p>&nbsp;</p>
        <p>At Astrocall, we strive to ensure that our users receive their purchases in a timely and efficient manner. While our primary services are digital and conducted through the Astrocall app and website, we recognize the importance of transparency regarding any shipping and delivery processes that may apply to physical products or materials provided through our services. The following outlines our shipping and delivery policy:</p>
        <p>&nbsp;</p><ol><strong>Digital Products and Services</strong></ol>
        <p>&nbsp;</p><ol><bold><strong>Immediate Access</strong></bold></ol>
        <p>All digital services and products purchased through Astrocall, including astrology consultations, reports, or readings, are delivered electronically. Users will receive immediate access to these services upon successful payment completion.</p>
        <p>&nbsp;</p><ol><strong>Confirmation Email</strong></ol><p>Upon purchase, users will receive a confirmation email detailing the transaction and providing instructions on how to access their digital services. This email will serve as a receipt for the purchase.</p>
        <p>&nbsp;</p><ol><strong>Physical Products</strong></ol><p>&nbsp;</p><ol><strong>Physical Deliveries</strong></ol><p>In the event that Astrocall offers physical products (e.g., printed astrology reports, books, or merchandise) through the website or app, the following terms apply:</p>
        <p>&nbsp;</p><ul><li><strong>Processing Time:</strong> Orders for physical products will be processed within 2-3 business days of the order confirmation. Users will receive an email notification once their order has been dispatched.</li><li><strong>Shipping Time:</strong> Delivery times will vary based on the shipping method selected at checkout and the delivery address provided. Typical delivery times are as follows:<ul><li>Standard Shipping: 5-10 business days</li><li>Expedited Shipping: 2-4 business days</li></ul></li></ul><p>&nbsp;</p>
        <p><strong>2.2 Shipping Costs</strong></p>
        <p>Shipping costs will be calculated at checkout based on the selected shipping method and the destination of the package. Users will see the total shipping fee before completing their purchase.</p>
        <p>&nbsp;</p><ol><strong>Order Tracking</strong></ol><p>&nbsp;</p><ol><strong>Tracking Information</strong></ol><p>For physical products shipped, users will receive tracking information via email once their order has been dispatched. This allows users to monitor the progress of their delivery in real-time.</p>
        <p>&nbsp;</p><ol><strong>Delivery Issues</strong></ol><p>If users experience any issues with their delivery, such as delays or lost packages, they should contact our support team at contact@astrocall.live. We will work with the shipping carrier to resolve any problems as quickly as possible.</p>
        <p>&nbsp;</p><ol><strong>International Shipping</strong></ol><p>&nbsp;</p><ol><strong>Availability</strong></ol><p>Astrocall may offer international shipping for physical products, subject to local regulations and shipping carrier capabilities. Users should ensure that their shipping address is complete and accurate to avoid delivery issues.</p>
        <p>&nbsp;</p><ol><strong>Customs and Duties</strong></ol><p>Users are responsible for any customs duties, taxes, or fees associated with international shipping. These charges may vary based on the destination country and are the responsibility of the recipient.</p>
        <p>&nbsp;</p><ol><strong>Changes to Shipping and Delivery Policy</strong></ol><p>&nbsp;</p>
        <p>Astrocall reserves the right to modify this Shipping and Delivery Policy at any time. Users will be notified of any significant changes through our website or app, and continued use of our services following such changes constitutes acceptance of the updated policy.</p>
        <p>&nbsp;</p>
        <p>For any questions or concerns regarding our Shipping and Delivery Policy, please contact us at contact@astrocall.live. We are committed to providing you with a seamless experience and appreciate your trust in our services.</p><p>&nbsp;</p>
      </div>


      <footer className="bg-body-tertiary text-center text-lg-start" style={{ position: 'fixed', bottom: '0px', width: '100%' }}>
        <div
          className="text-center p-2"
          style={{
            backgroundColor: '#ff6600',
            fontWeight: 'bold',
            paddingTop: '10px',
            paddingBottom: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          <button style={{ backgroundColor: "rgb(255, 102, 0)" }} onClick={() => { navigate("/privacypolicy") }}>Privacy Policy</button>
          <button style={{ backgroundColor: "rgb(255, 102, 0)" }} onClick={() => { navigate("/Termandcondition") }}>Terms and Conditions</button>
          <button style={{ backgroundColor: "rgb(255, 102, 0)" }} onClick={() => { navigate("/cancelandrefund") }}>Cancellation and Refund</button>
          <button style={{ backgroundColor: "rgb(255, 102, 0)" }} onClick={() => { navigate("/shipinganddelivery") }}>Shipping and Delivery</button>


          <span style={{ fontSize: '16px' }}>
            © 2021 Copyright :
          </span>
          <Link
            to={'https://arustu.com/'}
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: 'white',
              textDecoration: 'none'
            }}

          >
            Astrocall Live Services Private Limited
          </Link>
          <span style={{ fontSize: '16px' }}>
            All right Reserved
          </span>
        </div>
      </footer>



    </>
  );
};

export default ShippingAndDelivery;
