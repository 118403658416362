import React from 'react'
import { Link, useNavigate } from "react-router-dom";

const CancalationAndRefund = () => {
  const navigate=useNavigate();
  return (
    <>
     <div
      style={{
        backgroundColor: "rgb(250, 102, 0)",
        height: "60px",
        width: "100%", 
        position: "fixed",
        top: "0",
        left: "0",
        display: "flex", 
        alignItems: "center", 
        justifyContent: "space-between", 
        padding: "0 20px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", 
        zIndex: 1000,
      }}
    >
     
      <Link to="/" style={{ textDecoration: "none" }}>
        <h2 style={{ color: "#fff", margin: 0 }}>AstroCall</h2>
      </Link>
    
     
      <nav>
        <Link to="/" style={{ color: "#fff", margin: "0 10px", textDecoration: "none" }}>
          Home
        </Link>
      </nav>
    </div>
    <div style={{  paddingLeft: "150px",paddingTop:"100px", paddingRight: "150px",paddingBottom:"40px"}}>
      <h3 style={{textAlign:"center" ,fontWeight:"bold",textDecoration:"underline"}}><strong>&nbsp;REFUND AND CANCELLATION POLICY</strong></h3>
      <p>&nbsp;This Refund and Cancellation policy is an integral component of, and should be read in conjunction with, the Terms and Conditions of the website and Mobile Application. We retain the right to modify this Disclaimer at any given time. At Astrocall, we strive to provide a transparent and fair refund and cancellation policy for our users. Please review the following points carefully to understand our policy regarding refunds and cancellations:</p>
      <p><strong>&nbsp;CANCELLATION POLICY&nbsp;</strong></p>
      <p>At Astrocall, we understand that plans can change, and you may need to cancel a scheduled consultation. To ensure a fair and transparent process for all users, we have established the following cancellation policy:&nbsp;</p>
      <p>&nbsp;Cancellation of Scheduled Consultations</p>
      <p>Advance Notice Requirement</p>
      <p>&nbsp;Users may cancel a scheduled consultation through the Astrocall app or by emailing our support team at contact@astrocall.live. To avoid cancellation fees, cancellations must be made at least 24 hours before the scheduled consultation.</p>
      <p>&nbsp;Late Cancellations</p>
      <p>If a user cancels a consultation less than 24 hours before the scheduled time, a 50% cancellation fee will be charged based on the total consultation cost. This fee will be deducted from any potential refund amount.&nbsp;</p>
      <p>&nbsp;No-Show Policy</p>
      <p>If a user fails to attend a scheduled consultation without prior cancellation (a "no-show"), the total consultation fee will be forfeited, and no refund will be issued. This policy helps maintain a respectful environment for our practitioners and other users. </p>
      <p>&nbsp;Emergency Cancellations</p>
      <p>&nbsp;In cases of emergencies or unforeseen circumstances, users may contact our support team to discuss the situation. Astrocall reserves the right to evaluate such requests on a case-by-case basis and may waive cancellation fees at its discretion.&nbsp;</p>
      <p>&nbsp;Rescheduling Consultations</p>
      <p>&nbsp;Rescheduling Requests</p>
      <p>&nbsp;Users wishing to reschedule a consultation may do so by contacting us at least 24 hours in advance. Rescheduling is subject to the availability of the practitioner and may incur additional fees depending on the new consultation timing.&nbsp;</p>
      <p>No Rescheduling</p>
      <p>&nbsp;Cancellations made less than 24 hours before a consultation are not eligible for rescheduling; instead, users must adhere to the late cancellation policy.&nbsp;</p>
      <p><strong>REFUND POLICY</strong></p>
      <p>At Astrocall, we are committed to ensuring that our users have a positive experience. In line with this commitment, our refund policy provides clear guidelines for when and how users can request refunds for our services.</p>
      <p>&nbsp;Refund Eligibility</p>
      <p>&nbsp;Conditions for Refund</p>
      <p>&nbsp;Users may request a refund under the following circumstances:</p>
      <p><strong>Technical Issues</strong></p>
      <p>&nbsp;If the consultation was not conducted as scheduled due to technical issues on our part, users are entitled to a full refund.&nbsp;</p>
      <p><strong>Dissatisfaction with Service</strong>:Users who feel dissatisfied with the consultation service may request a refund, provided they notify us within 24 hours of the session. Users must include specific feedback regarding their experience. &nbsp;</p>
      <p><strong>Ineligible Refunds</strong></p>
      <p>Refund requests will not be processed under the following conditions:The user fails to attend a scheduled consultation without prior cancellation (no-show).The user cancels a consultation within 24 hours and incurs a cancellation fee. The service rendered falls under the category of non-refundable services as outlined at the time of purchase.&nbsp;</p>
      <p><strong>Refund Request Process</strong></p>
      <p><strong>How to Request a Refund:</strong>To initiate a refund request, users should contact us at contact@astrocall.live and provide the following details: &nbsp;User’s full name and account details. &nbsp;Date and time of the consultation. &nbsp;Reason for the refund request, including any specific issues encountered.&nbsp;</p>
      <p><strong>Processing Time</strong></p>
      <p>&nbsp;Once a refund request is received, our support team will review it within 5-7 business days. Users will be notified of the decision via email. If approved, refunds will be processed back to the original payment method used for the purchase.&nbsp;</p>
      <p><strong>&nbsp;Refund Methods</strong></p>
      <p><strong>&nbsp;Refund Processing</strong></p>
      <p>&nbsp;Refunds will be processed using the same payment method utilized for the original transaction. Users should allow 5-10 business days for the funds to reflect in their account, depending on the financial institution's processing times. </p>
      <p><strong>&nbsp;Changes to the Refund Policy</strong></p>
      <p>Astrocall reserves the right to modify this Refund Policy at any time. Any changes will be communicated through our website or app, and continued use of our services following such changes constitutes acceptance of the updated policys. For any questions or concerns regarding our Refund and Cancellation Policy, please contact us at contact@astrocall.live. Your satisfaction is our priority, and we are here to assist you.</p> html
      RefundCancellation.jsx:15
      <h3><strong>&nbsp;REFUND AND CANCELLATION POLICY</strong></h3>
      <p>&nbsp;This Refund and Cancellation policy is an integral component of, and should be read in conjunction with, the Terms and Conditions of the website and Mobile Application. We retain the right to modify this Disclaimer at any given time. At Astrocall, we strive to provide a transparent and fair refund and cancellation policy for our users. Please review the following points carefully to understand our policy regarding refunds and cancellations:</p>
      <p><strong>&nbsp;CANCELLATION POLICY&nbsp;</strong></p>
      <p>At Astrocall, we understand that plans can change, and you may need to cancel a scheduled consultation. To ensure a fair and transparent process for all users, we have established the following cancellation policy:&nbsp;</p>
      <p>&nbsp;Cancellation of Scheduled Consultations</p>
      <p>Advance Notice Requirement</p>
      <p>&nbsp;Users may cancel a scheduled consultation through the Astrocall app or by emailing our support team at contact@astrocall.live. To avoid cancellation fees, cancellations must be made at least 24 hours before the scheduled consultation.</p>
      <p>&nbsp;Late Cancellations</p>
      <p>If a user cancels a consultation less than 24 hours before the scheduled time, a 50% cancellation fee will be charged based on the total consultation cost. This fee will be deducted from any potential refund amount.&nbsp;</p>
      <p>&nbsp;No-Show Policy</p>
      <p>If a user fails to attend a scheduled consultation without prior cancellation (a "no-show"), the total consultation fee will be forfeited, and no refund will be issued. This policy helps maintain a respectful environment for our practitioners and other users. </p>
      <p>&nbsp;Emergency Cancellations</p>
      <p>&nbsp;In cases of emergencies or unforeseen circumstances, users may contact our support team to discuss the situation. Astrocall reserves the right to evaluate such requests on a case-by-case basis and may waive cancellation fees at its discretion.&nbsp;</p>
      <p>&nbsp;Rescheduling Consultations</p>
      <p>&nbsp;Rescheduling Requests</p>
      <p>&nbsp;Users wishing to reschedule a consultation may do so by contacting us at least 24 hours in advance. Rescheduling is subject to the availability of the practitioner and may incur additional fees depending on the new consultation timing.&nbsp;</p>
      <p>No Rescheduling</p><p>&nbsp;Cancellations made less than 24 hours before a consultation are not eligible for rescheduling; instead, users must adhere to the late cancellation policy.&nbsp;</p>
      <p><strong>REFUND POLICY</strong></p>
      <p>At Astrocall, we are committed to ensuring that our users have a positive experience. In line with this commitment, our refund policy provides clear guidelines for when and how users can request refunds for our services.</p>
      <p>&nbsp;Refund Eligibility</p>
      <p>&nbsp;Conditions for Refund</p>
      <p>&nbsp;Users may request a refund under the following circumstances:</p>
      <p><strong>Technical Issues</strong></p>
      <p>&nbsp;If the consultation was not conducted as scheduled due to technical issues on our part, users are entitled to a full refund.&nbsp;</p>
      <p><strong>Dissatisfaction with Service</strong>:Users who feel dissatisfied with the consultation service may request a refund, provided they notify us within 24 hours of the session. Users must include specific feedback regarding their experience. &nbsp;</p>
      <p><strong>Ineligible Refunds</strong></p>
      <p>Refund requests will not be processed under the following conditions:The user fails to attend a scheduled consultation without prior cancellation (no-show).The user cancels a consultation within 24 hours and incurs a cancellation fee. The service rendered falls under the category of non-refundable services as outlined at the time of purchase.&nbsp;</p>
      <p><strong>Refund Request Process</strong></p>
      <p><strong>How to Request a Refund:</strong>To initiate a refund request, users should contact us at contact@astrocall.live and provide the following details: &nbsp;User’s full name and account details. &nbsp;Date and time of the consultation. &nbsp;Reason for the refund request, including any specific issues encountered.&nbsp;</p>
      <p><strong>Processing Time</strong></p>
      <p>&nbsp;Once a refund request is received, our support team will review it within 5-7 business days. Users will be notified of the decision via email. If approved, refunds will be processed back to the original payment method used for the purchase.&nbsp;</p>
      <p><strong>&nbsp;Refund Methods</strong></p>
      <p><strong>&nbsp;Refund Processing</strong></p>
      <p>&nbsp;Refunds will be processed using the same payment method utilized for the original transaction. Users should allow 5-10 business days for the funds to reflect in their account, depending on the financial institution's processing times. </p>
      <p><strong>&nbsp;Changes to the Refund Policy</strong></p>
      <p>Astrocall reserves the right to modify this Refund Policy at any time. Any changes will be communicated through our website or app, and continued use of our services following such changes constitutes acceptance of the updated policys. For any questions or concerns regarding our Refund and Cancellation Policy, please contact us at contact@astrocall.live. Your satisfaction is our priority, and we are here to assist you.</p></div>
 
 
 
      <footer className="bg-body-tertiary text-center text-lg-start" style={{ position: 'fixed', bottom: '0px', width: '100%' }}>
                <div
                    className="text-center p-2"
                    style={{
                        backgroundColor: '#ff6600',
                        fontWeight: 'bold',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px'
                    }}
                >
                <button style={{backgroundColor:"rgb(255, 102, 0)"}} onClick={()=>{navigate("/privacypolicy")}}>Privacy Policy</button>
                <button style={{backgroundColor:"rgb(255, 102, 0)"}} onClick={()=>{navigate("/Termandcondition")}}>Terms and Conditions</button>
                <button style={{backgroundColor:"rgb(255, 102, 0)"}} onClick={()=>{navigate("/cancelandrefund")}}>Cancellation and Refund</button>
                <button style={{backgroundColor:"rgb(255, 102, 0)"}} onClick={()=>{navigate("/shipinganddelivery")}}>Shipping and Delivery</button>

                
                    <span style={{ fontSize: '16px' }}>
                        © 2021 Copyright :
                    </span>
                    <Link
                        to={'https://arustu.com/'}
                        style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: 'white',
                            textDecoration: 'none'
                        }}

                    >
                        Astrocall Live Services Private Limited
                    </Link>
                    <span style={{ fontSize: '16px' }}>
                        All right Reserved
                    </span>
                </div>
            </footer>

 
 </> )
}

export default CancalationAndRefund